import React from 'react';

function Footer() {
	return (
		<footer>
			<p>
				<span>+919555992069</span> | <span>info@odaduu.com</span>
			</p>
			<p>&copy; Odadu 2023</p>
		</footer>
	);
}

export default Footer;
